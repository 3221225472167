export var validatorNames = {
    one1qfpt5y3kuw8dnsd2d4lgjjzf849mfdzaxzum88 : "Guarda Wallet",
    one1l0wwv67lfelxhjvu6dkm9t8j5gsxev44lm6s02 : "InfStones",
    one1sw72xy472qsxqx7vesmy0va6wz5feukl2y4nps : "KryptoKnight",
    one1x8fhymx4xsygy4dju9ea9vhs3vqg0u3ht0nz74 : "RoboValidator",
    one14q6lh6l25c4p7yjlkpcsc4c23jtge6a33dt7qj : "KuCoin",
    one1fctfss8dr7prn602gnquf6xmdrphgftdfrca8x : "USS Harmony",
    one1ctwewx0pmg8k0tc8vnx4guyq9jm7dwz5k98tlm : "KysenPool Dragon",
    one1ksqcladc3r5s90v494h9tfwdhkx88tq6j549f6 : "Staking power",
    one14l4lst4qnm9fecv38450upfjym00s4ejz9mgdc : "Validator.ONE",
    one169pe3q35m7g30a965q94lr222mtyu4h2jdtwm9 : "StrongMindsHold",
    one1tghmnfksjg23c0jcnmkkeej6vrns2arwqp82qf : "Stake DAO",
    one1dcmp24uqgwszcvmm8n6r5dvhqhuukdj86pkg6n : "Staking4All",
    "one1xrlz4kjut6rpq4ghvernnjgxwcrq27kwqresgc": "Animoca Brands",
    "one1leh5rmuclw5u68gw07d86kqxjd69zuny3h23c3": "HarmonyHUB",
    "one16a36mcqgx96jxld5xy0zcj3ucyfz72xtxdyfjd": "Burning Validator (by Hound)",
    "one1upv5r5y3n5zce7a3wdj4z7pqzussqtdm35rcl5": "Binance Staking",
    "one18ky073zdrrmme3fs7h63wyzguuj6a3uukuc3gk": "Chickens Validator | Original Testnet Dev | Highest Individual Self-Stake (16M+) | 🐔",
    "one1hlxe68d7trza48k0n4y77antwaken3x99pgw75": "Gordo",
    "one17495f2cxc2f5ej2h3v76jhkxjhg84v2m4azf55": "Nobi",
    "one1c4w9danpa5v9zqurnl07lkqdcwyn3yfm86anqu": "Moto Trust - 5% Commission",
    "one1p2rmvndevvw682qynqu08hyvx24hh4runsw6pz": "RockTheBlockchain",
    "one1t3tex27l80cs4eltq5t7wymcxwwct6xxuyf7w4": "GGA Harmony Validator",
    "one12jell2lqaesqcye4qdp9cx8tzks4pega465r3k": "🌿 Mintbes",
    "one1v63cck8d08708mlgz8rmrku4l9jgvtmfn8a6wh": "DACM.io 1",
    "one1zcw3fua99t9te03mu6f5nne80p3rapfsxf2ur8": "OneBullValidator",
    "one13jewk8w7jah3r9jfahh8rlzpr5r6valascd0mn": "JungleCity",
    "one1xrtkrcpx7edw40zxpp26up939gc68u8hwepvnx": "Husaria ONE Validator",
    "one1v0n7nw6c4fe88xnuasr0d65luult0fvclvvxmf": "Fortune.ONE -- Core Validator | #1 Trusted | Fixed Min Fee | Auto Shard | 17M+ Rewards",
    "one1twcvkx63304dplxcmx0j9jm32u6er3k0hxuf7z": "ONECelestial Validator",
    "one18qk5uszfjq5wrkm2gfstqpx56jdpn0xd4563tk": "Tec Viva",
    "one14v636te9jxp4d9f2c5uwa9hvqmmmut4fdtpxw5": "AzureONE",
    "one132ggm0mgxdt4l8etud9e5gzpv8psgx30p7hxsq": "SargatxetPool",
    "one1qqqq23zjxevufqyez6khgtymku4g4jmgxpcp6n": "DAO Harmony Indonesia | Always BUILD",
    "one1y5aq9lyprhm2fzk8stqtz9nm0x90ggqfj0hw06": "Villain Validator",
    "one1uketsyw6xch45caup8a3fyjmc97x3q9876v44g": "metaONE KLAS",
    "one1fj55hlmffs3j5y7jn9f72e2dcf560q85end4jc": "Murph Validator",
    "one1kmlg23tk9xdn3nfhjsuje67t375y2zt5gaatvr": "HARMONY ROCKET",
    "one14gevvn4z6vmz6htkxwp62pe77c3dwj3p2cvrv6": "ENTER Group",
    "one1p2z4zeungp0c0uw4uw7g4avu9rtensjjulrctl": "Titus.ONE",
    "one1njzn5ujup8u0wgugyl4qugen5lnane9krwvpwy": "🐼 Panda Validator",
    "one1j34kvpa66hzqmf4ywa2cyhwn4se2a03lvhxv4n": "TokenNuggets",
    "one1qyfqyq73qdry2hasnzt5a88ngwhtt7qq4nv2nm": "Golden Koi",
    "one1sysxe76tej90vgy22cwfdah0h3t763n7utwach": "IDEAL | Mission Driven | Base Commission | Green Power",
    "one1j4kml3yqwlgurm4f7nwjcsuecu78f0n5y3lm8w": "KnowIt 💙Validator",
    "one1c3jsdzfe4tqxuhc0z98y92jfre8w2k4z7zukun": "Beyond.Harmony",
    "one1p7hpxunckupkdwsytjetnd95tqe3pnsccpw4uu": "Rutilant Hub",
    "one1f4ss7ekhd0jupg5w78s333ejw3ugrrumpjw6ja": "Valyd8Chain",
    "one1pk2ulhzxe8enytjmhh2c4g87jwr8gg552k9gvk": "💙 The Stable Europe Validator",
    "one13huhkfy9v0g6ygcptghljmem3yas3ceaeg9mg4": "Project Harmony",
    "one1ven7jzmcnnqynq0sjzkk5qatdyvnffyh9srghy": "ChainFi 👾 ",
    "one14sxss0luv7740y6crjqvzezj9a7tedg00608lc": "Findig Validator | by direktur.crypto",
    "one1e49ys34f7tsgq5ka9e4wxy5kfm6m5nwpfrkw89": "Recovery Validator",
    "one1rdmpel0fam355u3xdda2w037x6dq8n7xsj0e3v": "One Hispano",
    "one108uwrdejhf3es7rn6h4cdjqnvnpv75pp7t5ne9": "Mass Stake | Embiei ",
    "one1kf42rl6yg2avkjsu34ch2jn8yjs64ycn4n9wdj": "P-OPS Team",
    "one1aha9g2d6scsyktjgx7wm9jwssxjp6lrtl8959z": "三潭映月 (high stability 99.7%, low fee rate 5%)",
    "one1qm52lvursg036ly7zmmk4wstpfvnawncs45zvm": "CheekyOne",
    "one1mxjrugqety8t6v23m3et0j4zfussf4v8ktycur": "KRATOS 💙 (70M+ SELF STAKE & 27M+ LIFETIME REWARDS)",
    "one1r3kwetfy3ekfah75qaedwlc72npqm2gkayn6ue": "Solitaire",
    "one18hum2avunkz3u448lftwmk7wr88qswdlfvvrdm": "Chainode Tech - We know Tech! - Active Backup & Mngmt., Low Fee, HSM based Security",
    "one1y5686zfh8vnygxglrhztahh7hcn2tvk33vsgrt": "CypherMines",
    "one10rcp99jqrtdg7jtkwzf7xfp85thef08fu44vrf": "Sirius",
    "one1cv534gglmnumvdf35xhkvmt6mql60p2twy7p4w": "Nodeasy",
    "one1x8pupl36puw7cczjx6a93rfhh48rzldl0qxvk8": "Strat | Harmony Believer, 7.5M+ Reward Distributed, Lowest Fee & High Self Stake",
    "one1nnrr65ntny5f4dnvkmv65zqkr28uplwrx2du62": "Africa.One🐘",
    "one102lcjqy44ett8wu07dxdtce6gm988j0eu3z6cy": "TillyONE",
    "one1mlkylwnsgsam8cdxzn05hal3ytjngsunlpmp2j": "PeaceLoveHarmony.ONE",
    "one1s269hx6ch3u6hxuag5sp5ggwc68vem6d8du6ut": "One United Power || Guaranteed Low Commission",
    "one1kh9xzevupc60jyw3t0r7wgsgkml8kw8w32z3h3": "BrotherOne | 99.9% All Time Stability | Trusted By Onii-Chans | Indefinitely Low Fee",
    "one12szkssdcwttafxtayvq3l07d6ktaz43xasmsda": "Hound Validator | A % of our earnings will be donated to shelters",
    "one1xthdv58tg7fc2qv7ltu6399hnvwtvcld9krzrx": "Хинкали",
    "one19kfmnpj6p0g3ll2xevxw3agzppkwntfl6ue50u": "fellowship",
    "one1p370yr7xgn4300sseprfjscj6xnzmcv455k309": "Boss Baby ONE",
    "one193aqd5rmhr9prh2u8y6u4qftklpawk520l80u8": "Harmonious Dude",
    "one1rfpmq0kh56e6jpyq4c7fltp9ppt4hyj5p0m0ff": "PlanetOne",
    "one1frsph4jajyp9qnf6nw4eft70v0gula7tptmurd": "Rhythm",
    "one13kyypuaczvz7jdl64lht99m0xfqekxd3fcu2pm": "VietStaking [VN/EN/FR]",
    "one1prz9j6c406h6uhkyurlx9yq9h2e2zrpasr2saf": "Maffaz.One",
    "one192wqdhlk84lvxmrd6jl9l9njy7f94q4a3hd87w": "Quick.One|Lowest fees|High returns|Monthly Giveaways",
    "one1qk7mp94ydftmq4ag8xn6y80876vc28q7s9kpp7": "Smart Stake - harmony.smartstake.io & HarmonyAnalyticsBot",
    "one1ldthk5zyrea6w60rptcfyzlftd8fsn9fhkeps7": "SNZPool",
    "one1jfe5c30pcyxnzktqfv7w7qqnxysm5r3hlgcgq4": "VALIDERA | WIN MORE THAN STAKING REWARDS 🔥 | SWEEPSTAKES NOW LIVE! 🎁 | QUASARPROJECT.IO",
    "one1kyyt7j29h4uhtnuhfar5wmngntx4gterrkd8q9": "Total Harmony",
    "one1pzgc08u8xxj92srcgqutjkt6mmt6j8278zfpsk": "AffinityShard.ONE",
    "one1xazgn47pqv5ewh8aqercl3ptl7t4yhgpjryjer": "Repegging Node",
    "one1nkrwnhc9udee6400rncc06j3hhdmz6j3clj094": "Matrix Stake - Professional Staking - Top Stake - 8M Lifetime Reward - Low Fee",
    "one18julyys26h67r4vq3zexzpfmvt9vpn0g75phmu": "⚡ EasyNode.Pro Validator ⚡",
    "one10p0lx4ld8zwkhes7swv4pwzjhnegsm7dlc7s92": "GreenSky",
    "one139wxucm2rl0aag8ej24r2rh0qk86dylvszxg5u": "🔧Hank The Crank⚙️",
    "one1f08pfvn0refxv22kkrmgx59yucyu3e7rm3nl0m": "DEAD Pool",
    "one1rxdtt9fvgdcy3n5sy6e4zxg0s4hn4shaqf4qtp": "Tr4ck3r Validator",
    "one1f0pq9llvxlqqkfa0n98feuq2yw4yzdelrj37ny": "McONE",
    "one1atp5gxdxxt9yqux6kq64eacz7x8cyldejqjd9f": "Strata One 🛡️💰",
    "one15hs9d9kdnsd5jxjmu507wljcmmdfe390akxe3d": "GO GO a Genius One",
    "one1nf50jmyvckd0795mu4qhm6zfxppesulafejzvl": "CoinChowder 🇦🇺 ( ͡° ͜ʖ ͡°)",
    "one1q6mzfhp3n6r24zsfmqqhpz4g20jgn872vsum7s": "Farmony One",
    "one1a8x2c59aqj0qsl7n8tr2skfrp2yrx56f9uspe2": "Awoke2Crypto",
    "one13hr88u4zjrx2rf9gkdchawppdnsrctunlxug56": "ONE4All - tous pour one",
    "one13n974xtdzxu4uu2ugz8e2ml5p8t94d8x3tep3q": "Har(money)",
    "one18r3lv27kw5ye203m0x3w58cr734wy2wqa6l8ur": "JCR Validator",
    "one1m79e4ztrefz4nl475tw2ezvcw8a98nfhrzkxr0": "ONEMOON Validator",
    "one1y7d2cacm5f27cu5yqwl4mgeredjvtc5cz0gq26": "Coinboi",
    "one1qv82h8dzjmm09tqwdwe3evjgmzz84fqmqskkzk": "H1 Validator",
    "one182erdpf8y2cfj2x04v09p28xtp9kwgzngqflur": "MelodyONE",
    "one1txaatrq6cvm34gdgwegrzu97mrl7herh36m6yn": "Crypt0Tech 🇧🇷",
    "one1pygejp5kpe4llft4u90mvv9y0r27y84sdp4p9z": "Tigdar 🐯 Together we can!",
    "one19307tt49qdsjpwqljrefz6zj0prc645jpmpnnq": "Shillionaire Validator",
    "one1kfngqk6c2fnwjt9yyne6pqqeux0su3uzq0y5uw": "Hypermine",
    "one1d2zjm9czlfzdes97pfuzdydflw0t80kspwst0x": "KeepNode",
    "one1r55rwumsrm6w3d20uhaa3hm4rxr442k0qx9gj8": "Fuzz.Fi | Stake. Swap. Lend. Borrow. Win. Earn.",
    "one1ktmfrzjv3hwgtse4j03e2el8qqzu8qkfkjmr6t": "Harmony Node",
    "one1cjmpsjlc23jfpxealrrh6rxq8assjvll7z9kze": "Online Node",
    "one1wrvlznh27fywscexnc2l9fxk5gjelcqdnw8pvw": "BBIT",
    "one1m5awfzxfnux8ytthz7244t975eycn3m6wwtvwa": "Synclub",
    "one1r2lx24n0fpfch7cqyhccekqfd6dk79f0wqw7p4": "Legion - the ONE who is many | High Self Stake | Highest Uptime",
    "one1u3tlxeq9sl6qkmr8xd02zqa73lkrwkkujjl6d9": "TBD Investments",
    "one14xkvsj8gnwl2tnu9wahfru35x8ppveaqdx5cnf": "babylonodeONE",
    "one16knqzazp2kxra3zx4sw32capqt3esg55wjg7ff": "SoftNode",
    "one1j5gzpfcywjpapytn4awhrd7feas0yulk98mnyp": "modulo.so",
    "one1q73gcttk24690hdszqr3sfk6mhutj46y22tqqk": "Stake DAO [Retail]",
    "one1pr9wwqpamthyg6xvn2lcp26j0jphzvjuw22y64": "Equity Blok",
    "one1ktksx4t8t6grdllrf8vv78pj8pc6fwggvjhqzq": "Guardian of ONE",
    "one1ugvtdxau0mmd38wt42na9la9melp84alnfkyx4": "ONE1000Lakes",
    "one142jxj583t60fktsxe74hryllp6h2krjw5j0y3n": "MyCointainer.com2",
    "one1w7nvheulzwprf9d9a3r8sqtv5q47qlqx7kured": "DK Validator - Secure Independent Staking 🔐✅",
    "one17nfgz8rtgpl3nlws5q9tdk9y3puyqf847az6ne": "HashKey Capital",
    "one1zfnpdlr4v5hytm3gkm3z7a5d3c7sr69qg6aj4m": "Cool Banana",
    "one1hukwz92wuymvls8hrk4fhwclk0cer5r2f03mu4": "The ONE and only DCC",
    "one17eez4r5qmlcvk8dyv0eqc9vl79au5q3lsgegpx": "SonicSwapIO",
    "one1t95hnx0yg2jpnc6xj2lzltgrnzac6nzce6nerj": "Camouflage444",
    "one1djdj7g588xs4605laaxjxfrk0kuler2s6uasw6": "JStar is ON",
    "one1362p8nrtqymc9a4kj8gxlvc6l25cemmls0x5np": "Octeka.One",
    "one1kxjfy7f7xn56wn477t5y4rpkk5vkem4lpngkp3": "BoxedCloud🐬|| Low Commission Always || Community Always",
    "one1p7dxzv6znqtgt79py8lfunjd8a6kvlf85nn9xe": "All For One",
    "one16f3f9y4sqtrk3eq7gnagr4ac8p25rf08u0pxxp": "HashQuark01",
    "one1xrksyam05h78f47l70rtjsk5njy8u9f0ty995h": "Moonstake",
    "one1g0wg0mn4wwws2k2nwd32qvd84q9e0k9y34tgan": "PiStake",
    "one1q83ag25yvxzl753jjunw8rhvz03w2p55dneenm": "HashQuark02",
    "one1xm5xtttl7m4pmuzery23k4hvjkcl4v73ps8mnm": "Arcadian",
    "one10j0tswg6x4udqafvsetjj3fl0g4e52spwp0wsh": "Algorithmiq Ventures Validator Node - Brooklyn",
    "one1w9g7j40ctzlffmjrp6nmypdupsmyh0u90yn9xs": "FishFight.one|TrustlessTeam"
};